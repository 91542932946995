<template>
  <Error404 />
</template>

<script>
import Error404 from "@/components/error404/Error404.vue";

export default {
  name: "error404",
  components: {
    Error404,
  },
};
</script>
