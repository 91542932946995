<template>
  <section class="lh-404">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <a class="lh-Breadcrumb-link">404</a>
            </li>
          </ul>
          <div class="lh-Contact-form">
            <h1 class="lh-Form-title">Oups, c'est la guerre...</h1>
            <p>La page que vous souhaitez consulter est introuvable...</p>
            <router-link class="lh-Button" to="/boutique"
              >Retour à la boutique</router-link
            >
          </div>
        </div>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--dark">
        <div class="lh-404-bg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "error404",
};
</script>
<style lang="scss">
@import "@/components/error404/_index.scss";
</style>
